@font-face {
  font-family: 'Lefty-Icons';
  src:  url('fonts/Lefty-Icons.eot?b3nxh9');
  src:  url('fonts/Lefty-Icons.eot?b3nxh9#iefix') format('embedded-opentype'),
    url('fonts/Lefty-Icons.ttf?b3nxh9') format('truetype'),
    url('fonts/Lefty-Icons.woff?b3nxh9') format('woff'),
    url('fonts/Lefty-Icons.svg?b3nxh9#Lefty-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.material-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Lefty-Icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.material-icon-picture_template:before {
  content: "\e9a0";
  color: #666;
}
.material-icon-Activate:before {
  content: "\e99f";
  color: #666;
}
.material-icon-Change:before {
  content: "\e900";
  color: #666;
}
.material-icon-Brand_Safety:before {
  content: "\e99d";
  color: #666;
}
.material-icon-x:before {
  content: "\e99c";
}
.material-icon-Lefty:before {
  content: "\e99b";
}
.material-icon-quantity:before {
  content: "\e99a";
}
.material-icon-deactivate:before {
  content: "\e999";
}
.material-icon-Salesforce:before {
  content: "\e997";
}
.material-icon-Book:before {
  content: "\e998";
}
.material-icon-Offline:before {
  content: "\e996";
}
.material-icon-Shopify:before {
  content: "\e995";
}
.material-icon-Pin:before {
  content: "\e993";
}
.material-icon-Un-Pin:before {
  content: "\e994";
}
.material-icon-mark_posts:before {
  content: "\e992";
}
.material-icon-manage_posts:before {
  content: "\e98e";
}
.material-icon-edit_performance:before {
  content: "\e98f";
}
.material-icon-reject_posts:before {
  content: "\e990";
}
.material-icon-Select:before {
  content: "\e991";
}
.material-icon-Sync-Bis:before {
  content: "\e98b";
}
.material-icon-Hand-Delivered:before {
  content: "\e98d";
}
.material-icon-Affiliation:before {
  content: "\e982";
}
.material-icon-Analytics:before {
  content: "\e983";
}
.material-icon-Campaigns:before {
  content: "\e984";
}
.material-icon-Casting:before {
  content: "\e985";
}
.material-icon-Directory:before {
  content: "\e986";
}
.material-icon-Discover:before {
  content: "\e987";
}
.material-icon-Landing-Pages:before {
  content: "\e988";
}
.material-icon-Messages:before {
  content: "\e989";
}
.material-icon-Payments:before {
  content: "\e98a";
}
.material-icon-Product-Seeding-Alt:before {
  content: "\e98c";
}
.material-icon-Gift:before {
  content: "\e981";
}
.material-icon-Bank:before {
  content: "\e97a";
}
.material-icon-Payment-Active:before {
  content: "\e97c";
}
.material-icon-Payment-Default:before {
  content: "\e97d";
}
.material-icon-Pay:before {
  content: "\e980";
}
.material-icon-Products-Active:before {
  content: "\e97e";
}
.material-icon-Products-Default:before {
  content: "\e97f";
}
.material-icon-Messages-Active-v2:before {
  content: "\e978";
}
.material-icon-Messages-Default-v2:before {
  content: "\e979";
}
.material-icon-Credit-Card:before {
  content: "\e97b";
}
.material-icon-pdf_download:before {
  content: "\e977";
}
.material-icon-Calendar1:before {
  content: "\e975";
}
.material-icon-Show:before {
  content: "\e976";
}
.material-icon-Icon---Navigation---Collaboration---Inactive:before {
  content: "\e949";
}
.material-icon-Icon---Navigation---Collaboration---Active:before {
  content: "\e972";
}
.material-icon-Icon---Navigation---Settings---Inactive:before {
  content: "\e973";
}
.material-icon-Icon---Navigation---Settings---Active:before {
  content: "\e974";
}
.material-icon-Send-V2:before {
  content: "\e971";
}
.material-icon-Delete-V2:before {
  content: "\e970";
}
.material-icon-Chanel-Search:before {
  content: "\e96c";
}
.material-icon-Chanel-Profile:before {
  content: "\e96d";
}
.material-icon-Chanel-Filter:before {
  content: "\e96e";
}
.material-icon-Chanel-Export:before {
  content: "\e96f";
}
.material-icon-Wow:before {
  content: "\e96a";
}
.material-icon-Favorites:before {
  content: "\e96b";
}
.material-icon-Notes-v2:before {
  content: "\e969";
}
.material-icon-Casting-Active-v2:before {
  content: "\e967";
}
.material-icon-Casting-Default-v2:before {
  content: "\e968";
}
.material-icon-xlsx_download:before {
  content: "\e966";
}
.material-icon-Landing-Active:before {
  content: "\e964";
}
.material-icon-Landing-Default:before {
  content: "\e965";
}
.material-icon-Feedback-Info-outline:before {
  content: "\e961";
}
.material-icon-Feedback-Error-outline:before {
  content: "\e962";
}
.material-icon-Feedback-Success-outline:before {
  content: "\e963";
}
.material-icon-Help:before {
  content: "\e960";
}
.material-icon-Attribute:before {
  content: "\e95f";
}
.material-icon-Unarchive:before {
  content: "\e95d";
}
.material-icon-Archive:before {
  content: "\e95e";
}
.material-icon-Columns:before {
  content: "\e95c";
}
.material-icon-Add-Link:before {
  content: "\e94f";
}
.material-icon-Affiliation-Active:before {
  content: "\e950";
}
.material-icon-Affiliation-Default:before {
  content: "\e951";
}
.material-icon-Analytics-Active:before {
  content: "\e952";
}
.material-icon-Analytics-Default:before {
  content: "\e953";
}
.material-icon-Campaigns-Active:before {
  content: "\e954";
}
.material-icon-Campaigns-Default:before {
  content: "\e955";
}
.material-icon-Directory-Active:before {
  content: "\e956";
}
.material-icon-Directory-Default:before {
  content: "\e957";
}
.material-icon-Discovery-Active:before {
  content: "\e958";
}
.material-icon-Discovery-Default:before {
  content: "\e959";
}
.material-icon-Search-Active:before {
  content: "\e95a";
}
.material-icon-Search-Default:before {
  content: "\e95b";
}
.material-icon-Copy:before {
  content: "\e94e";
}
.material-icon-Options:before {
  content: "\e94a";
}
.material-icon-send:before {
  content: "\e94b";
}
.material-icon-Trello:before {
  content: "\e94c";
}
.material-icon-Calendar:before {
  content: "\e94d";
}
.material-icon-error:before {
  content: "\e901";
}
.material-icon-arrow_drop_down:before {
  content: "\e902";
}
.material-icon-play_arrow:before {
  content: "\e903";
}
.material-icon-more_vert:before {
  content: "\e904";
}
.material-icon-Add:before {
  content: "\e905";
}
.material-icon-settings:before {
  content: "\e906";
}
.material-icon-close:before {
  content: "\e907";
}
.material-icon-check_circle_outline:before {
  content: "\e908";
}
.material-icon-check_circle:before {
  content: "\e909";
}
.material-icon-radio_button_unchecked:before {
  content: "\e90a";
}
.material-icon-radio_button_checked:before {
  content: "\e90b";
}
.material-icon-indeterminate_check_box:before {
  content: "\e90c";
}
.material-icon-check_box_outline_blank:before {
  content: "\e90d";
}
.material-icon-check_box:before {
  content: "\e90e";
}
.material-icon-refresh:before {
  content: "\e90f";
}
.material-icon-bug_report:before {
  content: "\e910";
}
.material-icon-ppt_download:before {
  content: "\e911";
}
.material-icon-download:before {
  content: "\e912";
}
.material-icon-csv_download:before {
  content: "\e913";
}
.material-icon-at_sign:before {
  content: "\e914";
}
.material-icon-campaign:before {
  content: "\e915";
}
.material-icon-grid:before {
  content: "\e916";
}
.material-icon-list:before {
  content: "\e917";
}
.material-icon-person_add:before {
  content: "\e918";
}
.material-icon-info_feedback:before {
  content: "\e919";
}
.material-icon-warning_feedback:before {
  content: "\e91a";
}
.material-icon-error_feedback:before {
  content: "\e91b";
}
.material-icon-success_feedback:before {
  content: "\e91c";
}
.material-icon-likes:before {
  content: "\e91d";
}
.material-icon-comments:before {
  content: "\e91e";
}
.material-icon-views:before {
  content: "\e91f";
}
.material-icon-sort_active:before {
  content: "\e920";
}
.material-icon-sort_inactive:before {
  content: "\e921";
}
.material-icon-chevron_up:before {
  content: "\e922";
}
.material-icon-comments_outline:before {
  content: "\e923";
}
.material-icon-likes_outline:before {
  content: "\e924";
}
.material-icon-arrow_right:before {
  content: "\e925";
}
.material-icon-share:before {
  content: "\e926";
}
.material-icon-check:before {
  content: "\e927";
}
.material-icon-Refresh-Alt:before {
  content: "\e928";
}
.material-icon-add_campaign:before {
  content: "\e929";
}
.material-icon-more-horiz:before {
  content: "\e92b";
}
.material-icon-retweet:before {
  content: "\e92a";
}
.material-icon-clear:before {
  content: "\e92c";
}
.material-icon-instagram-post:before {
  content: "\e92d";
}
.material-icon-instagram-story:before {
  content: "\e92e";
}
.material-icon-Facebook:before {
  content: "\e92f";
}
.material-icon-Instagram:before {
  content: "\e930";
}
.material-icon-snapchat:before {
  content: "\e931";
}
.material-icon-twitter:before {
  content: "\e932";
}
.material-icon-At-Sign:before {
  content: "\e933";
}
.material-icon-blog:before {
  content: "\e934";
}
.material-icon-calc:before {
  content: "\e935";
}
.material-icon-city:before {
  content: "\e936";
}
.material-icon-delete:before {
  content: "\e937";
}
.material-icon-edit:before {
  content: "\e938";
}
.material-icon-zip_download:before {
  content: "\e939";
}
.material-icon-globe:before {
  content: "\e93a";
}
.material-icon-infos:before {
  content: "\e93b";
}
.material-icon-label:before {
  content: "\e93c";
}
.material-icon-labels:before {
  content: "\e93d";
}
.material-icon-location:before {
  content: "\e93e";
}
.material-icon-mail:before {
  content: "\e93f";
}
.material-icon-note:before {
  content: "\e940";
}
.material-icon-phone:before {
  content: "\e941";
}
.material-icon-profile:before {
  content: "\e942";
}
.material-icon-Filters:before {
  content: "\e943";
}
.material-icon-Open:before {
  content: "\e944";
}
.material-icon-Instagram-Video:before {
  content: "\e945";
}
.material-icon-Youtube:before {
  content: "\e946";
}
.material-icon-Tiktok:before {
  content: "\e947";
}
.material-icon-plus:before {
  content: "\e948";
}
.material-icon-minus:before {
  content: "\e99e";
}
.material-icon-notifications:before {
  content: "\eb47";
}
.material-icon-link:before {
  content: "\eb48";
}
.material-icon-search:before {
  content: "\eb49";
}
